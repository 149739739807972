<template>
  <div class="login-main">
    <div class="login-title"><img :src="lgtitle" alt="" /></div>
    <!-- 登录开始 -->
    <div class="login-box">
      <form :model="loginform">
        <nut-row>
          <nut-col :span="24" class="form-item">
            <nut-icon :name="loginform.userIcon" color="#fff" class="lableicon" size="18"></nut-icon>
            <nut-input placeholder="请输入账户" @change="change" @blur="userBlur" v-model="loginform.user" @clear="clear" />
          </nut-col>
          <nut-col :span="24" class="form-item">
            <nut-icon :name="loginform.pswIcon" color="#fff" class="lableicon" size="18"></nut-icon>
            <nut-input placeholder="请输入密码" @change="change" v-model="loginform.psw" type="password" @clear="clear" />
          </nut-col>
          <nut-col :span="24" class="form-item lg-btn">
            <nut-button plain type="info" class="white-btn" @click="loginClick()" :loading="isLoading">{{ loginContent }}
            </nut-button>
            <div class="blank10"></div>
            <nut-button plain type="info" class="whiteborder-btn" @click="phoneQuick()">手机快捷登录</nut-button>
          </nut-col>
        </nut-row>
        <div class="blank10"></div>
        <nut-row :gutter="20" class="re-info">
          <nut-col :span="12" class=""><a @click="toRegister()">立即注册</a></nut-col>
          <nut-col :span="12" class="text-rt"><a @click="toForgetPsw()">忘记密码</a></nut-col>
        </nut-row>
      </form>
    </div>
    <!-- 快捷登录 -->
    <div class="quick-lg">
      <ul>
        <li v-for="(item, id) in quickList" :key="id">
          <img :src="item.path" @click="quickClick(item.id)" />
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import { reactive, toRefs, getCurrentInstance, render, ref, onMounted } from 'vue'
import { useRouter } from 'vue-router';
import { Notify } from "@nutui/nutui";

export default {
  name: 'login',
  setup () {
    const router = useRouter()
    //axios 请求
    const { proxy } = getCurrentInstance();
    //Base64 加密解密
    let Base64 = require('js-base64').Base64;
    //企业ID
    let etpId = '';
    //企业名称
    let etpName = '';
    //用户ID
    let userId = '';
    //按钮不进入加载状态，可点击
    let isLoading = ref(false);
    //登录按钮名称
    let loginContent = ref("登录");
    const data = reactive({
      lgtitle: require('@/assets/img/login.jpg'),
    })
    const loginform = reactive({
      user: '',
      psw: '',
      userIcon: require('@/assets/img/usericon.png'),
      pswIcon: require('@/assets/img/pswicon.png'),
    })
    // 快捷登录
    const quickList = reactive([
      { path: require('@/assets/img/webchat.png'), id: '1' },
      /*{path: require('@/assets/img/weibo.png'), id: '2'},*/
      { path: require('@/assets/img/qq.png'), id: '3' },
    ])
    //账号输入框失焦后执行
    const userBlur = (value, event) => {
      //2、查询账号所属公司
      const etpUrl = "https://work.sview3d.com/phone/getEnterpriseAdmin";
      const etpParams = new URLSearchParams();
      etpParams.append('phoneOrEmail', value);
      proxy.$axios.post(etpUrl, etpParams).then(function (response) {
        console.log("-----------查询账号所属公司-------------")
        console.log(response)
        console.log("-----------查询账号所属公司 end-------------")
        let data1 = response.data;
        if (data1.success) {
          etpId = data1.innerId;
          etpName = data1.name;
        } else {
          //查询出不是企业管理员，则返回登陆用户的ID
          if (data1.innerId !== undefined) {
            userId = data1.innerId;
          }
          console.log("查询账号所属公司返回的错误信息：" + data1.msg)
        }
      }).catch(function (error) {
        console.log(error);
      });
    }

    // 方法调用
    const change = (value, event) => {
      console.log('change: ', value, event)
    }
    const clear = (value) => {
      console.log('clear:', value);
    };
    // 忘记密码调整
    const toForgetPsw = () => {
      router.push({ name: 'forgetPsw', params: { userId: loginform.user } })
    }
    //注册
    const toRegister = () => {
      router.push('/phoneWeb/register')
    }
    //登录
    const loginClick = () => {
      //1、校验手机号密码不能为空
      if (loginform.user == null || loginform.user === '') {
        Notify.warn('手机号不能为空！');
        return false;
      }
      if (loginform.psw == null || loginform.psw === '') {
        Notify.warn('密码不能为空！');
        return false;
      }
      //手机正则表达式
      const phoneRegular = /^1[3|4|5|7|6|8|9]\d{9}$/;
      //邮箱正则表达式
      const emailRegular = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(\.([a-zA-Z0-9_-])+)+$/;
      if (!phoneRegular.exec(loginform.user) && !emailRegular.exec(loginform.user)) {
        Notify.warn('请输入正确的手机号或邮箱!');
        return false;
      }
      //按钮进入加载状态，不可点击
      isLoading.value = true;
      loginContent.value = "登录中";
      //3、登录
      const url = "https://work.sview3d.com/user/login";
      const params2 = new URLSearchParams();
      params2.append('userId', loginform.user);
      params2.append('userPassword', loginform.psw);
      params2.append('etpId', etpId);
      proxy.$axios.post(url, params2).then(function (response) {
        console.log("-----------登录-------------")
        console.log(response)
        console.log("-----------登录 end-------------")
        let data1 = response.data;
        if (data1.reback) {
          Notify.success('欢迎登录', {
            duration: 1000
          });
          console.log("id解密：" + Base64.decode(data1.innerId));
          //如果没有企业，则跳转到登记页面
          if (etpId == null || etpId === '') {
            router.push({
              path: '/phoneWeb/CorporateInfoSet',
              query: {
                innerId: data1.innerId,
                phone: phoneRegular.exec(loginform.user) ? loginform.user : ''
              }
            });
          } else {
            //跳转到成员管理页面
            router.push({
              path: '/phoneWeb/member',
              query: {
                innerId: data1.innerId,
                etpName: Base64.encode(etpName)
              }
            });
          }
        } else {
          //如果登录接口返回用户加入的企业列表，则进入企业登记页面
          if (data1.etplist !== undefined && data1.etplist.length > 0) {
            console.log("企业列表", data1.etplist)
            router.push({
              path: '/phoneWeb/CorporateInfoSet',
              query: {
                innerId: Base64.encode(userId),
                phone: phoneRegular.exec(loginform.user) ? loginform.user : ''
              }
            });
          } else {
            proxy.$dialog({
              title: '错误提示',
              content: data1.msg,
              noCancelBtn: true
            });
            //按钮不进入加载状态，可点击
            isLoading.value = false;
            loginContent.value = "登录";
          }
        }
      }).catch(function (error) {
        proxy.$dialog({
          title: '错误提示',
          content: error,
          noCancelBtn: true
        });
        //按钮不进入加载状态，可点击
        isLoading.value = false;
        loginContent.value = "登录";
      });
    }
    //手机快捷登录
    const phoneQuick = () => {
      router.push('/phoneWeb/phoneLogin')
    }
    //第三方登录
    const quickClick = (value) => {
      //微信登录
      if (value === '1') {
        console.log('微信登录');
        if (isWechat()) {
          window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx0769bd9ed2f8ca60&redirect_uri=https://work.sview3d.com/weChatLogin&response_type=code&scope=snsapi_userinfo&state=0#wechat_redirect';
        } else {
          window.location.href = 'https://open.weixin.qq.com/connect/qrconnect?appid=wx6f4b7f8ec2c3fb85&redirect_uri=https://work.sview3d.com/weChatLogin&response_type=code&scope=snsapi_login&state=1#wechat_redirect';
        }
      }
      //QQ登录
      if (value === '3') {
        console.log('QQ登录');
        window.location.href = 'https://graph.qq.com/oauth2.0/authorize?response_type=code&client_id=101976897&redirect_uri=https://work.sview3d.com/qqLogin&scope=get_user_info';
      }
    }
    //判断是否是在微信内置游览器
    const isWechat = () => {
      const ua = navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        return true;
      } else {
        return false;
      }
    }
    return {
      ...toRefs(data),
      loginform,
      quickList,
      change,
      userBlur,
      clear,
      toForgetPsw,
      toRegister,
      loginClick,
      phoneQuick,
      isLoading,
      loginContent,
      quickClick,
      isWechat
    }
  },
}
</script>
<style scoped>
h3 {
  margin: 40px 0 0;
}
</style>
